import { createAction, props } from '@ngrx/store';

import { ExternalService, ExternalServiceMoreOptions } from '../../models';

export const loadRequest = createAction('[External Services] Load Request');

export const loadSuccess = createAction(
  '[External Services] Load Success',
  props<{ externalServices: ExternalService[] }>(),
);

export const loadFailure = createAction(
  '[External Services] Load Failure',
  props<{ error: any }>(),
);

export const loadMoreOptionsRequest = createAction(
  '[External Services] Load More Options Request',
  props<{ external_service_id: number }>(),
);

export const loadMoreOptionsSuccess = createAction(
  '[External Services] Load More Options Success',
  props<{ moreOptions: ExternalServiceMoreOptions }>(),
);

export const loadMoreOptionsFailure = createAction(
  '[External Services] Load More Options Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[External Services] Reset State');
