import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ExternalService } from '../../models';

import { featureAdapter, State } from './state';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const getMoreOptions = (state: State) => state.moreOptions;

export const selectState = createFeatureSelector<State>('externalServices');

export const selectAllItems = featureAdapter.getSelectors(selectState)
  .selectAll;

export const selectById = (id: string) =>
  createSelector(
    selectAllItems,
    (externalServices: ExternalService[]) =>
      externalServices && externalServices.find((p) => p.id === +id),
  );

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectMoreOptions = createSelector(selectState, getMoreOptions);
